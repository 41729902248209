<template>
  <div class="systemMesView">
    <pageHead title="成员加入申请" />
    <div v-infinite-scroll="loadMore" class="systemMesView_content">
      <applyItem
        v-for="item in records"
        :key="item.circleApplyRecordId"
        :item="item"
      ></applyItem>
      <no-more
        v-if="!params.hasNextPage && records.length > 0"
        style="margin-top: 14px"
      />
      <van-empty v-if="noData" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import pageHead from "@/components/pageHead";
import applyItem from "./applyItem";
import NoMore from "@/components/uitls/NoMore";
import { getMemberApplyFor } from "@/api/message";

export default {
  components: {
    pageHead,
    applyItem,
    NoMore,
  },
  data() {
    return {
      params: {
        type: 1,
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      records: [],
      noData: false,
    };
  },
  methods: {
    // 下拉请求
    loadMore() {
      this.loadList();
    },
    // 列表请求
    loadList() {
      let listQueryParams = this.params;
      if (listQueryParams.hasNextPage) {
        getMemberApplyFor(this.params)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages;
            listQueryParams.pageNum = listQueryParams.pageNum + 1;
            let records = this.records;
            res.records.forEach((item) => {
              let count = records.filter(
                (innerItem) =>
                  innerItem.circleApplyRecordId === item.circleApplyRecordId
              );
              if (count.length === 0) {
                records.push(item);
              }
            });
            this.records = records;
            if (this.records.length === 0) {
              this.noData = true;
            }
            // if (this.records.length === 0) {
            //   this.records = [{ name: 1 }];
            // }
          })
          .catch(() => {
            this.records = [{ name: 1 }];
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.systemMesView {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1;
    overflow-y: auto;
    padding: 0 29px;
    background-color: #ffffff;

    &_item {
      margin-top: 10px;
    }
  }
}
</style>
