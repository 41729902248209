<template>
  <div class="apply_item">
    <div class="header">
      <div class="people_info">
        <AvatarFrame
          class="header_img"
          :user-info="{'avatar': item.applyUserAvatar || '',
      avatarFrameUrl: item.applyUserAvatarFrameUrl || ''}"></AvatarFrame>
        <span class="name">{{ item.applyUsername }}</span>
      </div>

      <div class="operation">
        <van-button
          class="agree"
          color="#FE5800"
          round
          size="mini"
          type="info"
          v-show="item.applyStatus == 1"
          @click="operationbuttom()"
        >操作
        </van-button
        >
        <van-button
          class="agrees"
          color="#F6F6F6"
          round
          size="mini"
          type="default"
          v-show="item.applyStatus == 2"
        >已同意
        </van-button
        >
        <van-button
          class="agrees"
          color="#F6F6F6"
          round
          size="mini"
          type="default"
          v-show="item.applyStatus == 3"
        >已拒绝
        </van-button
        >
        <i></i>
      </div>
    </div>
    <div class="apply_info">
      <div class="content">
        <span class="label">申请加入：</span>
        <span class="text">{{ item.applyCircleName }}</span>
      </div>
      <div class="content">
        <span class="label">申请理由：</span>
        <span class="text">{{ item.applyReason }}</span>
      </div>
      <div class="content" v-show="item.applyStatus == 1">
        <span class="label">申请时间：</span>
        <span class="text">{{ item.applyTime }}</span>
      </div>
      <div class="content" v-show="item.applyStatus != 1">
        <span class="label">处理人：</span>
        <span class="text">{{ account }}</span>
      </div>
      <!-- <div class="content">
        <span class="label">申请状态：</span>
        <span class="text">{{
          item.applyStatus == 1
            ? "待处理"
            : item.applyStatus == 2
            ? "通过"
            : "拒绝"
        }}</span>
      </div> -->
    </div>
    <van-action-sheet v-model="show" cancel-text="取消" close-on-click-action>
      <div class="agree" @click="onCancel(item, 1)">同意加入</div>
      <div class="refused" @click="onCancel(item, 2)">拒绝加入</div>
    </van-action-sheet>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {agreeMemberApplyFor, refusedMemberApplyFor} from "@/api/message";

export default {
  name: "systemItem",
  components: {
    AvatarFrame
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data () {
    return {
      show: false,
      account: "",
    };
  },
  created () {
    this.account = this.$store.getters.user.account;
    // console.log(this.$store.getters.user.account);
  },
  methods: {
    // 操作按钮
    operationbuttom () {
      this.show = true;
    },
    // 同意拒绝点击事件
    onCancel (item, type) {
      console.log(item.id, item.applyCircleId);
      const formData = new FormData();
      formData.append("circleApplyRecordId", item.circleApplyRecordId);
      formData.append("circleId", item.applyCircleId);
      // 同意
      if (type === 1) {
        console.log(formData);
        agreeMemberApplyFor(formData).then(() => {
          this.item.applyStatus = 2;
        });
      }
      // 拒绝
      else {
        console.log(formData);
        refusedMemberApplyFor(formData).then(() => {
          this.item.applyStatus = 3;
        });
      }
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-button--mini {
    padding: 2px 10px;
  }
}

.apply_item {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid #f4f4f4;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .people_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header_img {
        width: 44px;
        height: 44px;
      }

      .name {
        margin-left: 10px;
        font-size: 16px;
        color: #000000;
        line-height: 18px;
      }
    }

    .operation {
      display: flex;
      flex-direction: row;
      align-items: center;

      .agree {
        idth: 68px;
        font-size: 14px;
      }

      .agrees {
        color: #6d7278 !important;
      }
    }
  }

  .apply_info {
    margin-left: 54px;
    padding-top: 16px;
    margin-top: 6px;
    border-top: 1px solid #f4f4f4;

    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 8px;

      .label {
        width: 70px;
        font-size: 14px;
        color: #000000;
        line-height: 18px;
      }

      .text {
        flex: 1;
        font-size: 14px;
        color: $moreColor;
        line-height: 18px;
        word-break: break-all;
      }
    }
  }

  .agree {
    text-align: center;
    font-size: 16px;
    line-height: 58px;
    border-bottom: 1px solid #e5e5e5;
  }

  .refused {
    text-align: center;
    font-size: 16px;
    height: 57px;
    line-height: 57px;
  }
}
</style>
